import React from "react"
import {
  Dropdown,
  Form,
  Message,
  Popup,
  Icon,
  Button,
  Radio,
} from "semantic-ui-react"

export default class CustomFieldTemplate extends React.Component {
  render() {
    const {
      id,
      label,
      displayLabel,
      rawHelp,
      description,
      rawErrors,
      children,
      schema,
      uiSchema,
    } = this.props

    let childrenProps = children.props
    let disabled = false

    if (schema && schema.type === "object") {
      const { formData, uiSchema } = children.props
      childrenProps = {
        ...childrenProps,
        uiSchema: Object.keys(uiSchema).reduce((newUiSchema, key) => {
          const uiOptions = (uiSchema[key] || {})["ui:options"]
          if (
            (uiOptions &&
              uiOptions.enabledIf &&
              uiOptions.hasValue &&
              formData[uiOptions.enabledIf] !== uiOptions.hasValue) ||
            (uiOptions &&
              uiOptions.enabledIf2 &&
              uiOptions.hasValue2 &&
              formData[uiOptions.enabledIf2] !== uiOptions.hasValue2)
          ) {
            newUiSchema[key] = {
              ...uiSchema[key],
              "ui:options": {
                ...uiOptions,
                disabled: true,
              },
            }
          } else {
            newUiSchema[key] = uiSchema[key]
          }
          return newUiSchema
        }, {}),
      }
    } else {
      if (
        uiSchema &&
        uiSchema["ui:options"] &&
        uiSchema["ui:options"].disabled
      ) {
        disabled = true
        childrenProps = {
          ...childrenProps,
          disabled,
        }
      }
    }

    return (
      <Form.Field
        disabled={disabled}
        error={!!rawErrors && rawErrors.length > 0}
      >
        {rawHelp && (
          <Popup
            trigger={
              <Icon style={{ float: "right" }} link circular name="help" />
            }
            content={rawHelp}
          />
        )}
        {displayLabel && <label htmlFor={id}>{label}</label>}
        {id !== "root" && description}

        {{ ...children, props: childrenProps }}

        {!!rawErrors &&
          rawErrors.length > 0 && <Message negative list={rawErrors} />}
      </Form.Field>
    )
  }
}
