// @flow

import * as React from "react"
import { Message, Segment, Icon } from "semantic-ui-react"
import Joyride from "react-joyride"
// $FlowFixMe
import "react-joyride/lib/react-joyride-compiled.css"
// import Perf from 'react-addons-perf'

import {
  contractWizard,
  fixpriceWizard,
  type WizardSteps,
} from "./JoyrideSteps"
import FormSteps from "./FormSteps"
import TemplatePageForm from "./TemplatePageForm"
import { hasLocalStorage } from "./utils"

import "./ContractWizard.css"

export type Props = ContractWizardProps

type State = {
  formData: Array<FormData>,
  completeUpTo: number,
  submitted: boolean,
  // True once the user has started making changes
  dirty: boolean,
  showTourBeacon: boolean,
  autoStartTour: boolean,
  joyrideSteps: WizardSteps,
}

class ContractWizard extends React.Component<Props, State> {
  static defaultProps = {
    enableJoyride: true,
  }

  timer = null

  joyride = null

  constructor(props: Props) {
    super(props)

    let showTourBeacon = false

    if (props.enableJoyride && hasLocalStorage()) {
      showTourBeacon = !localStorage.getItem("ShowContractWizardTour")
    }

    this.state = {
      formData: [...props.initialFormData],
      completeUpTo: Math.max(props.initialFormData.length - 1, 0),
      submitted: false,
      dirty: false,
      showTourBeacon,
      autoStartTour: false,
      joyrideSteps: [],
    }
  }

  componentDidMount() {
    if (this.props.template.kind === "Contract") {
      this.setState({ joyrideSteps: contractWizard })
    } else {
      this.setState({ joyrideSteps: fixpriceWizard })
    }
  }

  componentWillUnmount() {
    if (this.timer != null) {
      clearTimeout(this.timer)
    }
    // If we're umounting, it could either because the form is being submitted,
    // or because the user is leaving the page. In the first case, we don't want
    // to signal a change because this will set the state back from completed to
    // editing.
    if (!this.state.submitted && this.state.dirty) {
      this.props.onChange(this.state.formData)
    } /*else if (!this.state.submitted) {
      console.log("Not saved because editor is not dirty")
    } */
  }

  /*
  componentDidUpdate() {
    Perf.stop()
    //Perf.printInclusive()
    Perf.printWasted()
  }
*/
  handleChange = (change: { formData: FormData }) => {
    this.updateFormDataState(change, true)
    // Perf.start()
    if (this.timer != null) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      if (!this.state.submitted && this.state.dirty) {
        this.props.onChange(this.state.formData)
      } /*else if (!this.state.submitted) {
        console.log("Not saved because editor is not dirty")
      } */
    }, 5000)
  }

  handleInitializeDefaults = (change: { formData: FormData }) => {
    this.updateFormDataState(change, false)
  }

  updateFormDataState({ formData }: { formData: FormData }, dirty: boolean) {
    this.setState({
      formData: [
        ...this.state.formData.slice(0, this.props.page),
        formData,
        ...this.state.formData.slice(this.props.page + 1),
      ],
      dirty,
    })
  }

  handleNextPage = () => {
    const { onPageComplete, onChange, page } = this.props
    this.setState({ completeUpTo: this.props.page + 1 }, done => {
      onPageComplete(page, this.props.template.pages.length)
      onChange(this.state.formData)
    })
  }

  handleChangeToPage = (index: number) => {
    this.props.goToPage(index)
    this.props.onChange(this.state.formData)
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () =>
      this.props.onContractComplete(this.state.formData)
    )
  }

  handleJoyrideEvent = (event: { type: string, index: number }) => {
    switch (event.type) {
      case "finished":
        if (hasLocalStorage) {
          localStorage.setItem("ShowContractWizardTour", "Done")
        }
        break
      default:
    }
    this.props.onJoyrideEvent && this.props.onJoyrideEvent(event)
  }

  handleRestartJoyride = (event: Event) => {
    event.preventDefault()
    this.setState({ autoStartTour: true }, () => {
      if (this.joyride != null) {
        this.joyride.reset(true)
      }
    })
  }

  getSchemaAsObject(schema: string | Schema) {
    if (schema instanceof String || typeof schema === "string") {
      try {
        return JSON.parse(schema)
      } catch (e) {
        console.error("Could not parse JSON: ", schema)
        console.error(e)
      }
      return {}
    } else {
      return schema
    }
  }

  render() {
    const {
      template: { kind, name, pages },
      footerLeft,
      page,
      isCreating,
      createErrors,
      enableJoyride,
      validate,
    } = this.props

    const isLastPage = page === pages.length - 1
    const isFirstPage = page === 0
    const hasFooter = footerLeft != null || enableJoyride
    return (
      <div className="ContractWizard">
        {enableJoyride && (
          <Joyride
            ref={c => (this.joyride = c)}
            steps={this.state.joyrideSteps}
            run={this.state.showTourBeacon}
            scrollToSteps={false}
            showSkipButton={true}
            showStepsProgress={true}
            autoStart={this.state.autoStartTour}
            type="continuous"
            callback={this.handleJoyrideEvent}
            locale={{
              back: "Zurück",
              close: "Hilfe schliessen",
              last: "Einführung Beenden",
              next: "Weiter",
              skip: "Einführung Beenden",
            }}
          />
        )}
        <FormSteps
          pages={pages}
          current={page}
          onStepClicked={this.handleChangeToPage}
          completeUpTo={this.state.completeUpTo}
        />
        <TemplatePageForm
          onSubmit={isLastPage ? this.handleSubmit : this.handleNextPage}
          formSchema={this.getSchemaAsObject(pages[page].formSchema)}
          uiSchema={this.getSchemaAsObject(pages[page].uiSchema)}
          formData={this.state.formData[page]}
          previewFormData={Object.assign({}, ...this.state.formData)}
          onChange={this.handleChange}
          onInitializeDefaults={this.handleInitializeDefaults}
          loading={isCreating}
          isFirstPage={isFirstPage}
          page={page}
          isLastPage={isLastPage}
          templateName={name}
          validate={validate}
          previewTemplate={
            kind === "Contract" ? pages[page].previewTemplate : undefined
          }
        >
          {createErrors && (
            <Message negative>
              <Message.Header>Ups, ein Fehler ist aufgetreten:</Message.Header>
              <p>
                Error {createErrors.statusCode}: {createErrors.status}
              </p>
            </Message>
          )}
        </TemplatePageForm>
        {hasFooter && (
          <Segment className="bottom" attached="bottom">
            {footerLeft}
            {enableJoyride && (
              <em>
                Einführung{" "}
                <a href="#restart-joyride" onClick={this.handleRestartJoyride}>
                  nochmals anzeigen
                </a>
                <Icon name="help circle" size="large" />
              </em>
            )}
          </Segment>
        )}
      </div>
    )
  }
}

export default ContractWizard
