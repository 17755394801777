// @flow

import * as React from "react"
import { Grid, Button, Label, Popup } from "semantic-ui-react"
import JsonSchemaForm from "react-jsonschema-form"
import { getDefaultFormState } from "react-jsonschema-form/lib/utils"

import Preview from "./Preview"
import CustomFormWidgets, {
  ArrayFieldTemplate,
  transformErrorsToGerman,
} from "./CustomFormWidgets"

import CustomFieldTemplate from "./CustomFieldTemplate"

import "./TemplatePageForm.css"

class SubmitButton extends React.Component<
  { loading: boolean, isLastPage: boolean },
  void
> {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.loading !== this.props.loading ||
      nextProps.isLastPage !== this.props.isLastPage
    )
  }

  render() {
    const { loading, isLastPage } = this.props
    return (
      <Button
        fluid
        type="submit"
        loading={loading}
        disabled={loading}
        color={isLastPage ? "teal" : null}
        content={
          isLastPage
            ? "Bearbeitung abschliessen"
            : "Weiter zum nächsten Schritt"
        }
        icon={isLastPage ? "download" : "right arrow"}
        labelPosition="right"
      />
    )
  }
}

export type Props = {
  templateName: string,
  onSubmit?: () => void,
  onChange: (change: FormData) => void,
  onInitializeDefaults: (change: FormData) => void,
  formSchema: Schema,
  uiSchema: Schema,
  formData: FormData,
  previewFormData: FormData,
  loading: boolean,
  isLastPage: boolean,
  page: number,
  isFirstPage: boolean,
  previewTemplate: ?string,
  validate?: (formData: FormData, errors: {}) => void,
  children?: React.Node,
}

class TemplatePageForm extends React.Component<Props, void> {
  componentDidMount() {
    this.mergeWithDefaultFormsState(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.page !== nextProps.page) {
      this.mergeWithDefaultFormsState(nextProps)
    }
  }

  mergeWithDefaultFormsState({
    formSchema,
    formData,
    onInitializeDefaults,
  }: Props) {
    const defaults = getDefaultFormState(formSchema, formData)
    onInitializeDefaults({ formData: defaults })
  }

  render() {
    const {
      templateName,
      onSubmit,
      onChange,
      formSchema,
      uiSchema,
      formData,
      previewFormData,
      loading,
      isLastPage,
      previewTemplate,
      validate,
      children,
    } = this.props
    // console.log("Rendering schema", formSchema)
    // console.log("Rendering with formData", formData)
    const showPreview = !!previewTemplate
    return (
      <Grid
        className="attached segment TemplatePageForm"
        stackable
        columns={showPreview ? 2 : 1}
      >
        <Grid.Row>
          <Grid.Column>
            <JsonSchemaForm
              className="ui form"
              FieldTemplate={CustomFieldTemplate}
              ArrayFieldTemplate={ArrayFieldTemplate}
              widgets={CustomFormWidgets}
              onSubmit={onSubmit}
              schema={formSchema}
              uiSchema={uiSchema}
              formData={formData}
              liveValidate={false}
              noValidate={false}
              validate={validate}
              showErrorList={false}
              transformErrors={transformErrorsToGerman}
              onChange={onChange}
            >
              {children}
              {onSubmit && (
                <SubmitButton loading={loading} isLastPage={isLastPage} />
              )}
            </JsonSchemaForm>
          </Grid.Column>
          {showPreview && previewTemplate != null && (
            <Grid.Column>
              <Popup
                trigger={
                  <Label color="teal" ribbon="right">
                    Vorschau
                  </Label>
                }
                content="Die Vorschau zeigt nur einen Ausschnitt des Textes an.."
              />
              <Preview
                title={templateName}
                variables={previewFormData}
                template={previewTemplate}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    )
  }
}

export default TemplatePageForm
