// @flow

import * as React from "react"
import Velocity from "velocityjs"

import paperclip from "./paperclip.png"

import "./Preview.css"

export type Props = {
  title: string,
  variables: FormData,
  template: string,
}

export default class Preview extends React.PureComponent<Props> {
  renderTemplate(template: string, variables: FormData) {
    const asts = Velocity.parse(template)
    const compile = new Velocity.Compile(asts)
    return compile.render(variables) // replace \n with <br/> in the variables
  }

  render() {
    const { title, variables, template } = this.props

    let __html = null

    try {
      __html = this.renderTemplate(template, variables) // TODO drop "" variables
    } catch (exception) {
      __html = this.renderTemplate(template, {})
    }

    __html = __html
      .replace(/\$[\w.]+/gi, "_____")
      .replace(/\n\n(\n)+/gi, "\n\n")

    return (
      <div className="Preview">
        <img className="paperclip" alt="" src={paperclip} />
        <div className="title">—{title}—</div>
        <div className="content" dangerouslySetInnerHTML={{ __html }} />
      </div>
    )
  }
}
