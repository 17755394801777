// @flow

import * as React from "react"
import { Step, Button } from "semantic-ui-react"

import "./FormSteps.css"

export type Props = {
  pages: Array<WizardPage>,
  current?: number,
  completeUpTo?: number,
  onStepClicked?: (index: number) => void,
  disabled?: boolean,
}

export default class FormSteps extends React.Component<Props, void> {
  handleStepClicked = (i: number) => () => {
    if (this.props.onStepClicked) {
      this.props.onStepClicked(i)
    }
  }

  render() {
    const { pages, current, completeUpTo, disabled = false } = this.props

    const steps = pages.map(({ title, description, icon }, i) => (
      <Step
        className={current != null && i === current - 1 ? "previous" : ""}
        key={i}
        as={Button}
        onClick={this.handleStepClicked(i)}
        active={!disabled && i === current}
        disabled={disabled || i >= completeUpTo + 1}
        title={title}
      />
    ))

    return (
      <Step.Group
        className={`FormSteps ${disabled ? " allDisabled" : ""}`}
        attached="top"
      >
        {steps}
      </Step.Group>
    )
  }
}
