// @flow

// https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
export function hasLocalStorage() {
  const type = "localStorage"
  try {
    const storage = window[type]
    const x = "__storage_test__"
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}
