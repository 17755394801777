// @flow

import * as React from "react"

const defaultStyle = {
  mainColor: "#e3b23c",
  beacon: {
    inner: "#e3b23c",
    outer: "#e3b23c",
  },
}

const formStepsContract = {
  text: (
    <div>
      <p>
        In dieser kurzen Einführung werden wir Sie mit den wichtigsten Schritten
        bei der Erstellung eines Vertrages oder Rechtsdokuments vertraut machen.
      </p>
      <p>
        Am oberen Bildschirmrand sehen Sie die verschiedenen Formularseiten. Sie
        können jederzeit zwischen den ausgefüllten Seiten hin- und her
        navigieren.
      </p>
    </div>
  ),
  selector: ".FormSteps",
  style: defaultStyle,
}

const formStepsFixprice = {
  text: (
    <div>
      <p>
        In dieser kurzen Einführung werden wir Sie mit den wichtigsten Schritten
        vertraut machen.
      </p>
      <p>
        Am oberen Bildschirmrand sehen Sie die verschiedenen Formularseiten. Sie
        können jederzeit zwischen den ausgefüllten Seiten hin- und her
        navigieren.
      </p>
    </div>
  ),
  selector: ".FormSteps",
  style: defaultStyle,
}

const formStep1 = {
  text: <p>Sie starten immer auf der ersten Seite mit der Eingabe.</p>,
  selector: ".FormSteps > .step:first-child",
  style: defaultStyle,
}

const inputs = {
  text: (
    <p>
      Jede Formularseite umfasst verschiedene Eingabe- und Auswahlfelder, die
      Sie vervollständigen müssen. Gewisse Eingaben sind nicht zwingend nötig,
      diese sind dann entsprechend gekennzeichnet.
    </p>
  ),
  selector: ".TemplatePageForm > .column:first-child > .ui.form > .field",
  style: defaultStyle,
}

const preview = {
  text: (
    <div>
      <p>
        Hier sehen Sie eine Vorschau des Dokuments. Ihre Eingaben fliessen
        direkt in die Vorschau ein. So sehen Sie, in welchem Kontext die
        Eingaben im fertigen Dokument stehen werden und Sie können Ihre
        Antworten entsprechend formulieren.
      </p>
      <p>
        Beachten Sie, dass die Vorschau nur einen Ausschnitt des endgültigen
        Dokuments zeigt und nicht den ganzen Inhalt.
      </p>
    </div>
  ),
  selector: ".TemplatePageForm .Preview",
  style: defaultStyle,
}

const nextButton = {
  text: (
    <div>
      <p>
        Sind Sie mit den Eingaben fertig, gelangen Sie hier zur nächsten
        Formularseite.
      </p>
    </div>
  ),
  selector: ".TemplatePageForm button[type='submit']",
  style: defaultStyle,
}

const saveForLater = {
  text: (
    <div>
      <p>
        Wenn Sie zu einem späteren Zeitpunkt weiterarbeiten möchten, können Sie
        sich hier einen Link zuschicken lassen mit dem Sie wieder auf die
        aktuelle Formularseite mit Ihren Angaben gelangen.
      </p>
    </div>
  ),
  selector: ".SaveForLater",
  style: defaultStyle,
}

export type WizardSteps = Array<{
  text: React.Node,
  selector: string,
  style: {
    mainColor: string,
    beacon: {
      inner: string,
      outer: string,
    },
  },
}>

export const contractWizard: WizardSteps = [
  formStepsContract,
  formStep1,
  inputs,
  preview,
  nextButton,
  saveForLater,
]

export const fixpriceWizard: WizardSteps = [
  formStepsFixprice,
  formStep1,
  inputs,
  nextButton,
  saveForLater,
]
