// @flow

function handleStringType(key, { title }, value, result) {
  // console.log("Handle String:", key)
  // console.log("--> Schema", schema)
  // console.log("--> Form Value", value)
  if (value) {
    result.push({ key, title, value })
  }
}

function handleArrayType(key, schema, formValues, result) {
  // console.log("Handle Array:", key)
  // console.log("--> Schema", schema)
  // console.log("--> Form Values", formValues)
  const arrayResult = []
  const {
    title,
    items: { type, properties },
  } = schema
  switch (type) {
    case "number":
    case "string":
    case "boolean":
      for (let i in formValues) {
        handleStringType(key, {}, formValues[i], arrayResult)
      }
      result.push({
        key,
        title,
        value: arrayResult,
      })
      break
    case "object":
      for (let i in formValues) {
        handleObjectType(key, { properties }, formValues[i], arrayResult)
      }
      result.push({ key, title, value: arrayResult })
      break
    default:
      throw new Error(`Unsupported schema type ${type} in array`)
  }
}

function handleObjectType(key, { properties }, formValues, result) {
  // console.log("Handle Object:", key)
  // console.log("--> Schema", schema)
  // console.log("--> Form Values", formValues)
  for (let key in properties) {
    const { type } = properties[key]
    switch (type) {
      case "string":
      case "number":
      case "boolean":
        handleStringType(key, properties[key], formValues[key], result)
        break
      case "array":
        handleArrayType(key, properties[key], formValues[key], result)
        break
      default:
        throw new Error(`Unsupported schema type ${type} in object`)
    }
  }
}

export default function(
  wizardPages: Array<WizardPage>,
  formValues: Array<FormData>
): KeyTitleValueArray {
  if (formValues.length < wizardPages.length) {
    console.error(
      `Numer of wizard pages (${
        wizardPages.length
      }) does not match formValues (${formValues.length}): `,
      wizardPages,
      formValues
    )
  }

  const schemas = wizardPages.map(({ formSchema }) => JSON.parse(formSchema))

  let result = []

  for (let i = 0; i < schemas.length; i++) {
    handleObjectType("ROOT", schemas[i], formValues[i], result)
  }

  return result
}
