import React from "react"
import { Dropdown, Form, Button, Radio } from "semantic-ui-react"
import DescriptionField from "react-jsonschema-form/lib/components/fields/DescriptionField"

export function transformErrorsToGerman(errors) {
  return errors
    .map(error => {
      if (
        error.name === "type" &&
        error.schema &&
        error.schema.type === "number"
      ) {
        return {
          ...error,
          message: "Bitte geben Sie eine Zahl ein.",
        }
      }
      return null
    })
    .filter(Boolean)
}

class TextWidget extends React.Component {
  handleChange = (event: Event) => {
    this.props.onChange(event.target.value)
  }

  render() {
    const {
      id,
      value,
      readonly,
      autofocus,
      required,
      label, // eslint-disable-line
      options, // eslint-disable-line
      schema, // eslint-disable-line
      formContext, // eslint-disable-line
      registry, // eslint-disable-line
      placeholder, // eslint-disable-line
    } = this.props

    return (
      <div id={id} className="ui input">
        <input
          type="text"
          autoFocus={autofocus}
          autoComplete={options.autocomplete}
          readOnly={readonly}
          required={this.props.disabled ? false : required}
          value={typeof value === "undefined" ? "" : value}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
/*


class CheckboxWidget extends React.Component {
  handleChange = (event: Event, data) => {
    event.preventDefault()
    this.props.onChange(data.checked)
  }

  render() {
    const {
      id,
      label,
      value,
      readonly,
      autofocus,
      required,
      disabled,
    } = this.props

    const checked = typeof value === "undefined" ? false : value

    return (
      <Checkbox
        id={id}
        label={label}
        checked={checked}
        autoFocus={autofocus}
        readOnly={readonly}
        onClick={this.handleChange}
        required={required}
        disabled={disabled}
        toggle
      />
    )
  }
}

*/

class CheckboxWidget extends React.Component {
  render() {
    const {
      schema,
      id,
      value,
      disabled,
      readonly,
      label,
      autofocus = false,
      onChange,
    } = this.props
    return (
      <div className={`checkbox ${disabled || readonly ? "disabled" : ""}`}>
        {schema.description && (
          <DescriptionField description={schema.description} />
        )}
        <label>
          <input
            type="checkbox"
            id={id}
            checked={typeof value === "undefined" ? false : value}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            onChange={event => onChange(event.target.checked)}
          />
          <span>
            &nbsp;
            {label}
          </span>
        </label>
      </div>
    )
  }
}

class RadioWidget extends React.Component {
  handleChange = (event: Event, { value }) => {
    event.preventDefault()
    this.props.onChange(value)
  }

  render() {
    const { id, options, value, required, disabled, readonly } = this.props
    const { enumOptions } = options

    return (
      <div id={id} className="field-radio-group">
        {enumOptions.map((option, i) => {
          const checked = option.value === value
          const disabledCls = disabled || readonly ? "disabled" : ""
          const radio = (
            <Form.Field>
              <Radio
                label={option.label}
                value={option.value}
                checked={checked}
                required={required}
                disabled={disabled || readonly}
                onChange={this.handleChange}
              />
            </Form.Field>
          )
          return (
            <div key={i} className={`radio ${disabledCls}`}>
              <label>{radio}</label>
            </div>
          )
        })}
      </div>
    )
  }
}

class SelectWidget extends React.Component {
  state = {
    options: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      options: this.calculateOptions(props),
    }
  }

  componentDidMount() {
    if (!this.props.value) {
      this.props.onChange(this.state.options[0].value)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const old = JSON.stringify(this.props.options.enumOptions)
    const next = JSON.stringify(nextProps.options.enumOptions)

    if (old !== next) {
      this.setState({
        options: this.calculateOptions(nextProps),
      })
    }
  }

  calculateOptions(props) {
    const dropdownOptions = props.options.enumOptions.map(
      ({ value, label }) => {
        return { text: label, value: value }
      }
    )

    let additionalOption = []
    if (
      props.value &&
      !props.options.enumOptions.find(({ value }) => value === props.value)
    ) {
      additionalOption = [{ text: props.value, value: props.value }]
    }
    return [...additionalOption, ...dropdownOptions]
  }

  handleAddition = (e, { value }) => {
    if (
      this.state.options.find(
        ({ value: predefinedValue }) => value === predefinedValue
      )
    ) {
      return
    }

    this.setState({
      options: [{ text: value, value }, ...this.state.options],
    })
    this.props.onChange(value)
  }

  handleOnChange = (event, { value }) => {
    this.props.onChange(value)
  }

  render() {
    const {
      value,
      id,
      required,
      placeholder = "Treffen Sie eine Auswahl",
      options: { allowAdditions = false },
    } = this.props

    return (
      <Dropdown
        id={id}
        required={required}
        options={this.state.options}
        placeholder={placeholder}
        basic
        search={allowAdditions}
        selection
        allowAdditions={allowAdditions}
        additionLabel=""
        value={value}
        onAddItem={this.handleAddition}
        onChange={this.handleOnChange}
      />
    )
  }
}

export function ArrayFieldTemplate({
  className,
  items,
  canAdd,
  title,
  onAddClick,
}) {
  return (
    <div className={className}>
      <p>{title}</p>
      {items &&
        items.map(element => (
          <div
            key={element.index}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <div style={{ flexGrow: 1 }}>{element.children}</div>
            <Button
              circular
              disabled={!element.hasMoveDown}
              icon="chevron down"
              onClick={element.onReorderClick(element.index, element.index + 1)}
            />
            <Button
              circular
              disabled={!element.hasMoveUp}
              icon="chevron up"
              onClick={element.onReorderClick(element.index, element.index - 1)}
            />
            <Button
              circular
              icon="remove"
              color="red"
              onClick={element.onDropIndexClick(element.index)}
            />
          </div>
        ))}
      {canAdd && (
        <Button
          style={{ marginTop: "1em" }}
          icon="plus"
          labelPosition="left"
          content="Eintrag zur Liste hinzufügen"
          onClick={onAddClick}
        />
      )}
    </div>
  )
}

export default {
  TextWidget,
  RadioWidget,
  SelectWidget,
  CheckboxWidget,
}
